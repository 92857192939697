.slideShow{
    width: 100%;
    height: auto;
    object-fit: cover;
    .owl-theme{
       background-position: bottom ;
        .item{
        width: 100%;
        height: auto !important;
        overflow: hidden;
        object-fit: cover;
        img{
            width: 100% !important;
            height:auto;
        }
    }
    }
   
}