.navbar-toggler {
    width: 20px;
    height: 20px;
    position: relative;
    transition: 0.5s ease-in-out;
}

.container-fluid {
    width: 100%;
    padding: 0px 40px;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
    position: relative;
}

.navbar-toggler span {
    margin: 0;
    padding: 0;
}

.toggler-icon {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: black;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
}

.middle-bar {
    margin-top: 0px;
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
    position: absolute;
    top: 0px;
    transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
    position: absolute;
    top: 10px;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
    position: absolute;
    top: 20px;
    transform: rotate(0deg);
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
    top: inherit;
    transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
    opacity: 0;
    top: inherit;
    filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
    top: inherit;
    transform: rotate(-135deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
    background: black;
}

.nav-item {
    padding-bottom: 10px;
}

.nav-item:hover {
    transition: all 0.3s ease-in;
    color: #fdb653;
}