.error {
  padding: 100px;
  width: 100%;

  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .error_wrap {
    width: 100%;
    height: auto;
    max-width: 1400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 60px 100px;
    .error_img {
      width: 100%;
    }
    .error_text {
      width: 100%;
      h1 {
        font-size: 80px;
        line-height: 1;
      }
      p {
        padding: 30px 0px;
      }
      button {
        background-color: #1c8aeb;
        border: none;
        padding: 10px 30px;
        border-radius: 30px;
        color: white;
      }
    }
  }
}
