.pay_icon {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #090909;
  span {
    padding-top: 10px;
    font-size: 12px;
    color: #595959;
    text-transform: uppercase;
  }

  .icons {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50px !important;
      padding: 10px 10px;
    }
  }
}
