.whatapp-icon {
    position: fixed;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 99999999;
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    padding-bottom: 20px;
}

.whatapp-icon img {
    width: 55px;
    height: 55px;
    padding: 5px;
}

.what-text {
    background-color: white;
    padding: 3px 10px;
    border-radius: 2rem;
}

.svg-style {
    pointer-events: none;
    display: block;
    height: 55px;
    width: 55px;
}