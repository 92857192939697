.payment {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  padding: 60px 20px;
  justify-content: space-between;
  max-width: 1000px;
  // background-color: gray;
  .pay_info {
    width: 100%;
    height: auto;
    .check_form {
      background-color: rgb(244, 244, 244);
      width: 100%;
      padding: 20px;
      border-radius: 5px;
      margin-top: 20px;
      strong {
        font-size: 14px !important;
        font-weight: 600 !important;
        margin-bottom: 10px !important;
      }
      form {
        width: 100%;
        margin-top: 10px;
        .from_wrp {
          display: grid;
          gap: 30px;
          margin-bottom: 10px;
          grid-template-columns: 1fr 1fr;
          .input_wrp {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            label {
              font-size: 14px;
              margin-bottom: 5px;
            }
            input {
              padding: 3px 10px;
              border-radius: 5px;
              outline: none;
              border: none;
              //   border: 1px solid #250e62;
            }
          }
        }
        @media screen and (max-width: 960px) {
          .from_wrp {
            grid-template-columns: 1fr;
          }
        }
        .pay_btn {
          margin-top: 30px;
        }
      }
    }
    .pro_list {
      width: 100%;
      height: auto;
      .all_pro_list {
        width: 100%;
        max-height: 300px;
        overflow: scroll;
        padding: 10px 5px;
        .pro_card {
          flex-direction: column;
          padding: 15px 5px;
          margin-bottom: 10px;
          width: 100%;
          align-items: center;
          display: flex;
          gap: 10px;
          justify-content: space-between;
          .pro_des {
            border-bottom: 1px solid lightgrey;
            padding-bottom: 5px;
            em {
              font-style: normal;
              line-height: 0.8;
              font-size: 13px !important;
              color: rgb(105, 105, 105) !important;
            }
          }
        }
        .pro_card_in {
          padding: 5px 15px;
          width: 100%;
          align-items: center;
          display: flex;
          gap: 10px;
          justify-content: space-between;
          .img_wrp {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            width: 70px;
            overflow: hidden;
            img {
              width: 100%;
              object-fit: contain !important;
            }
          }
          .info_wrp {
            span {
              color: #2879fe !important;
              font-size: 13px !important;
              font-weight: 600 !important;

              em {
                color: #2879fe !important;
                font-size: 13px !important;
                font-weight: 600 !important;
                font-style: normal;
              }
            }
          }
        }
      }
    }
    .msgShow {
      width: 100%;
      span {
        padding: 4px 10px;
        background-color: yellow;
        font-size: 12px;
      }
    }
  }
  .total_wrp {
    padding: 5px 10px;
    border-radius: 5px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    input {
      height: 30px;
      padding: 2px 10px;
      width: 100px;
      border-radius: 5px;
      outline: none;
      border: none;
    }
  }
  .bg {
    background-color: rgba(255, 166, 0, 0.437);
  }
}

@media screen and (max-width: 960px) {
  .payment {
    grid-template-columns: 1fr;
  }
}
