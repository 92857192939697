.succsess {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  padding: 50px;
  text-align: center;
  .card_wrap {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    height: auto;
    width: 50vw;
    padding: 50px;
    -webkit-box-shadow: 0px 0px 7px -5px rgb(0, 0, 0);
    box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
    background-color: rgb(255, 255, 255);
    .logo {
      width: 100%;
      img {
        width: 100px;
      }
    }
    .btn {
      padding-top: 30px;
      button {
        border: 2px solid black;
        background: none;
        padding: 10px 30px;
        border-radius: 30px;
        color: rgb(0, 0, 0);
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .succsess {
    width: 100%;
    height: auto;

    .card_wrap {
      height: auto;
      width: 100%;
      padding: 50px;
      .logo {
        width: 100%;
        img {
          width: 100px;
        }
      }
      h1 {
        font-size: 10px;
      }
    }
  }
}

.failed {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  padding: 50px;
  text-align: center;
  .card_wrap {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    height: auto;
    width: 50vw;
    padding: 50px;
    -webkit-box-shadow: 0px 0px 7px -5px rgb(0, 0, 0);
    box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
    background-color: rgb(255, 255, 255);
    .logo {
      width: 100%;
      img {
        width: 100px;
      }
    }
    .btn {
      padding-top: 30px;
      button {
        border: 2px solid black;
        background: none;
        padding: 10px 30px;
        border-radius: 30px;
        color: rgb(0, 0, 0);
      }
    }
  }

  @media screen and (max-width: 992px) {
    .succsess {
      width: 100%;
      height: auto;

      .card_wrap {
        height: auto;
        width: 100%;
        padding: 50px;
        .logo {
          width: 100%;
          img {
            width: 100px;
          }
        }
        h1 {
          font-size: 10px;
        }
      }
    }
  }
}
