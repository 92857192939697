.products {
  padding: 30px 50px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 2rem;
  width: 100%;

  .left {
    flex: 1;
    position: sticky;
    height: 100%;
    top: 50px;

    h2 {
      font-size: 400;
      margin-bottom: 20px;
    }
    .inputItem {
      margin-bottom: 10px;
      label {
        margin-left: 10px;
      }
    }

    .filterItem {
      margin-bottom: 30px;
    }
  }
  .right {
    text-align: center;
    justify-content: center;
    align-items: center;
    flex: 3;
    .free_banner{
      width: 100%;
      overflow: hidden;
      border-radius: 10px;
      img{
        width: 100%;
      }
    }

    .catImg {
      width: 100%;
      height: 300px;
      object-fit: cover;
      margin-bottom: 50px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .products {
    padding: 30px;
    grid-template-columns: 1fr;
    .left {
      display: none !important;
    }
    .navbar-nav {
      width: 100%;
      padding-top: 10px;
    }
    .right{
      flex: 1;
    }
  }
}

.pro_head{
  text-align: center;
  justify-content: center;
  display: flex;
  text-align: center;
  width: 100% !important;
  padding: 50px 10px !important;
}
