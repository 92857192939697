.loading_wrp {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  z-index: 99;
  backdrop-filter: blur(2px);
  background-color: #ffffffd7;
  z-index: 999;
  position: fixed;
}
